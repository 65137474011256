import "./styles.css";
import ClientDashboard from "pages/clientDashboard";
import AdminDashboard from "pages/adminDashboard";
import { useAuth } from "hooks/useAuth";
type Props = {};

const Dashboard = (props: Props) => {
  const auth = useAuth();

  return (
    <div className="project_lists">
      <div className="container-fluid">
        {auth?.user?.roleId == 2 && <ClientDashboard />}
        {auth?.user?.roleId == 1 && <AdminDashboard />}
      </div>
    </div>
  );
};

export default Dashboard;

import {get} from "lodash"
import { Auth } from "types/Auth" 
import BaseService from "./Base"
import apiUrls from "./ApiUrl";

class AuthService extends BaseService {
    constructor() {
        super(process.env.REACT_APP_API_BASE_URL)
    }

    async getOtp(data: any, params = {}) {
        try{
            const response = await this.post(apiUrls.sendOTP, data, params)
            return get(response, "data.data", {})
        } catch (error) {
            throw error;
        }
    }

    async otpSignIn(data: Auth.SignInParams, params = {}) {
        try{
            const response = await this.post(apiUrls.login, data, params)
            return get(response, "data.data", {})
        } catch (error) {
            throw error;
        }
    }

    async signIn(data: Auth.SignInParams): Promise<Auth.SignInResponse> {
        try {
            const response = await this.post(apiUrls.login, data);
            return get(response, "data", {})
        } catch (e: any) {
            throw e?.response?.data
        }
    }

    async signInAsClient(data = {}): Promise<Auth.SignInResponse> {
        try {
            const response = await this.post(apiUrls.loginAs, data)
            return get(response, "data", {})
        } catch (e: any) {
            throw e?.response?.data
        }
    }

    async sendOTPByHash(data = {}): Promise<Auth.SignInResponse> {
        try {
            const response = await this.post(apiUrls.sendOTP, data)
            return get(response, "data", {})
        } catch (e: any) {
            throw e?.response?.data
        }
    }

    async verifyOTP(data = {}): Promise<Auth.SignInResponse> {
        try {
            const response = await this.post(apiUrls.verifyPassword, data)
            return get(response, "data", {})
        } catch (e: any) {
            throw e?.response?.data
        }
    }
    async resetPassword(data = {}): Promise<any> {
        try {
            const response = await this.post(apiUrls.resetPassword, data)
            return get(response, "data", {})
        } catch (e: any) {
            throw e?.response?.data
        }
    }
    
    async changePassword(data = {}): Promise<any> {
        try {
          const response = await this.post(apiUrls.changePassword, data);
          return get(response, "data", {});
        } catch (e: any) {
          throw e?.response?.data;
        }
      }
    
    // async ssoSignIn(data: Auth.SsoParams): Promise<Auth.SignInResponse> {
    //     try {
    //         const response = await this.post("/auth/v2/sso-sign-in", data)
    //         return get(response, "data.data", {})
    //     } catch (e: any) {
    //         throw e.response.data
    //     }
    // }

    // async getTokenFromRedirect(token): Promise<any> {
    //     try {
    //         const response = await this.post("/auth/v2/get-token-from-redirect", token)
    //         return get(response, "data.data", {})
    //     } catch (e: any) {
    //         throw e.response.data
    //     }
    // }

    // async getMe(): Promise<any> {
    //     try {
    //         const response = await this.get("/auth/v2/get-me")
    //         return get(response, "data.data", {})
    //     } catch (e: any) {
    //         throw e.response.data
    //     }
    // }

    // async getAuthClients(): Promise<any> {
    //     try {
    //         const response = await this.get(`/auth/v2/get-auth-clients?slug=${SlugHelper.generate()}`)
    //         return get(response, "data.data", {})
    //     } catch (e: any) {
    //         throw e.response.data
    //     }
    // }
}

export default AuthService

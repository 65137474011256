const ApiUrls = {
  //Auth APIs
  loginAs: "/v1/auth/login-as",
  login: "/v1/auth/sign-in",
  sendOTP: "/v1/auth/send-otp",
  resetPassword: "/v1/auth/reset-password",
  verifyPassword: "/v1/auth/verify-otp",
  refreshToken: "/v1/auth/refresh-token",

  //Project APIs
  projectList: "/v1/client-project/list",
  projectCreate: "/v1/client-project/create",
  projectUpdate: "/v1/client-project/update",
  projectDelete: "/v1/client-project/delete",

  // Chatbot APIs
  categoryList: "/v1/category/list",
  clientPrompt: "/v1/client-prompt",

  chatbotList: "/v1/client-prompt/list",
  chatbotCreate: "/v1/client-prompt/create",
  chatbotDelete: "/v1/client-prompt/delete",
  getEmbedPrompts: "/v1/client-prompt/get-embed",
  updateClientPrompt: "/v1/client-prompt/update",
  uploadFile: "/v1/upload/file",
  clientPromptEmbeded: "/v1/client-prompt-embedding",
  countToken: "/v1/client-prompt-embedding/count-token",
  clientPromptEmbeddedMapping: "/v1/client-prompt-embed-mapping",
  lead: "/v1/lead",
  conversation: "/v1/conversations",
  webHook: "/v1/client-prompt-action",
  webHookPlatform: "/v1/web-hook/platform",

  // Clients API
  clientList: "/v1/client/list",
  clientCreate: "/v1/client/create",
  clientUpdate: "/v1/client/update",
  clientDelete: "/v1/client/delete",

  promptList: "/v1/prompt/list",
  promptCreate: "/v1/prompt/create",
  promptUpdate: "/v1/prompt/update",
  promptDelete: "/v1/prompt/delete",
  genericPrompts: "/v1/generic-prompt",
  pricingFeature: "/v1/pricing-feature",
  pricing: "/v1/pricing",
  leadStatus: "/v1/lead-status",
  questionAnswer: "/v1/question-answer",
  report: "/v1/report",
  validationType: "/v1/validation-type/list",
  customValidationParameter: "/v1/custom-validation-parameter/list",
  parameterInputType: "/v1/parameter-input-type/list",
  conversationsDownload: "/v1/conversations/chat-text-file",

  // Create User and Roles
  roleList: "/v1/role/list",
  userList: "/v1/user/list",
  createUser: "/v1/user/create",
  updateUser: "/v1/user/update",
  activeSwitch: "#",
  changePassword: "/v1/auth/change-password",
};

export default ApiUrls;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.roleInputForm {
  padding: 0.1rem;
  font-size: 14px;
  color: #666666;
  border: none;
  border-bottom: 1px solid #efefef;
  margin-top: 0.2rem;
  width: 100%;
  outline: none;
}

label {
  font-weight: 500;
  display: block;
}

.staffTable {
  min-height: 30vh;
  height: 78vh;
  width: 100%;
  background-color: white;
}
`, "",{"version":3,"sources":["webpack://./src/pages/staff/staff.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,eAAe;EACf,cAAc;EACd,YAAY;EACZ,gCAAgC;EAChC,kBAAkB;EAClB,WAAW;EACX,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,WAAW;EACX,uBAAuB;AACzB","sourcesContent":[".roleInputForm {\n  padding: 0.1rem;\n  font-size: 14px;\n  color: #666666;\n  border: none;\n  border-bottom: 1px solid #efefef;\n  margin-top: 0.2rem;\n  width: 100%;\n  outline: none;\n}\n\nlabel {\n  font-weight: 500;\n  display: block;\n}\n\n.staffTable {\n  min-height: 30vh;\n  height: 78vh;\n  width: 100%;\n  background-color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { styled, Typography } from "@mui/material";
import { Avatar, IconButton, Toolbar } from "@mui/material";
import assets from "../../assets";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import MuiAppBar from "@mui/material/AppBar";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useAppDispatch, useTypedSelector } from "redux/store";
import {
  logoutStore,
  selectIsShowSuperAdminButton,
  setAppState,
  updateSuperAdminState,
} from "redux/features/appStateSlice";
import AlertConfirmation from "components/AlertConfirmation";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Logout from "@mui/icons-material/Logout";

import {
  getOldToken,
  getOldUser,
  clearStoreById,
  storageKey,
} from "helpers/storage";
import { toastError } from "helpers/toastHelper";
import { resetChatbot } from "redux/features/chatbotSlice";
import { resetInbox } from "redux/features/inboxSlice";
import { resetPF } from "redux/features/pricingFeatureSlice";
import { resetPricing } from "redux/features/pricingSlice";
import { resetProject } from "redux/features/projectSlice";
import { resetPrompt } from "redux/features/promptSlice";
import { resetQusAns } from "redux/features/questionAnswerSlice";
import { useAuth } from "hooks/useAuth";

type AppBarProps = {
  theme?: any;
  open: boolean;
  position?: string;
};
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop: any) => prop !== "open",
})(({ theme, open }: AppBarProps) => ({
  // zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${sizeConfigs.sidebar.width})`,
    marginLeft: `${sizeConfigs.sidebar.width}`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(!open && {
    width: `calc(100% - ${theme.spacing(7)} - 20px)`,
    marginLeft: `calc(${theme.spacing(7)} + 1px)`,
  }),
}));

const Topbar = ({
  open = false,
  handleDrawerOpen = () => null,
  handleDrawerClose = () => null,
}: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openDropdown = Boolean(anchorEl);
  const isShowSuperAdminButton = useTypedSelector(selectIsShowSuperAdminButton);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const auth = useAuth();
  const clientType = process.env.REACT_APP_CLIENT_TYPE || "default";
  const [colors, setColors] = useState(colorConfigs.default);

  useEffect(() => {
    setColors(
      clientType == "jaipuria" ? colorConfigs.jaipuria : colorConfigs.default
    );
    checkOldUserExist();
  }, []);

  const checkOldUserExist = async () => {
    const token = await getOldToken();
    const user = await getOldUser();
    if (!!token && !!user) {
      dispatch(updateSuperAdminState({ isShow: !isShowSuperAdminButton }));
    }
  };

  const goBackToSuperAdmin = async () => {
    const token = await getOldToken();
    const user = await getOldUser();
    if (!!token && !!user) {
      dispatch(setAppState({ token, user }));
      clearStoreById(storageKey.oldToken);
      clearStoreById(storageKey.oldUser);
      navigate("/clients", { replace: true });
      cleanAllState();
    } else {
      toastError("User Not found!");
    }
    dispatch(updateSuperAdminState({ isShow: !isShowSuperAdminButton }));
  };

  const cleanAllState = () => {
    dispatch(resetChatbot);
    dispatch(resetInbox);
    dispatch(resetPF);
    dispatch(resetPricing);
    dispatch(resetProject);
    dispatch(resetPrompt);
    dispatch(resetQusAns);
  };

  const onPressLogout = () => setIsOpen(true);

  const onAcceptForLogout = () => {
    dispatch(logoutStore());
    navigate("/");
    cleanAllState();
    window.location.reload();
  };

  const handleMenu = (event: any) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const upgradeHandler = () => {
    navigate("/billing/pricing");
  };

  const onProfilePage = () => {
    navigate("/profile");
    setAnchorEl(null);
  };

  return (
    <>
      <AppBar
        position="fixed"
        open={open}
        sx={{
          boxShadow: colors.toolbar.shadow,
        }}
      >
        <Toolbar
          sx={{
            background: colors.topbar.topbarGradient,
            color: colors.topbar.bg,
          }}
        >
          <IconButton
            color="inherit"
            aria-label="close drawer"
            onClick={handleDrawerClose}
            edge="start"
            sx={{
              marginRight: 2,
              display: "none",
              ...(open && { display: "block" }),
            }}
          >
            {theme.direction === "ltr" ? (
              <span className="material-symbols-outlined">menu</span>
            ) : (
              <span className="material-symbols-outlined">menu</span>
            )}
          </IconButton>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 2,
              ...(open && { display: "none" }),
            }}
          >
            <span className="material-symbols-outlined">menu_open</span>
          </IconButton>
          <div className="header-top-section">
            <Typography variant="h6" noWrap component="div">
              {/* Chat GPT App */}
            </Typography>
            <div className="chat_title">
              {/* {auth?.user?.roleId != 1 && (
                <a href="" onClick={upgradeHandler}>
                  {" "}
                  Upgrade Account
                </a>
              )} */}
              {isShowSuperAdminButton && (
                <a href="" onClick={goBackToSuperAdmin}>
                  {" "}
                  <ArrowBackIosIcon /> Back to Super Admin
                </a>
              )}{" "}
              {"  "}
              <div className="notify">
                {/* <NotificationsNoneIcon
                  sx={{
                    color: colorConfigs.sidebar.bg,
                    marginRight: 1,
                    marginLeft: 1,1
                  }}
                ></NotificationsNoneIcon> */}
                <div className="material-symbols-outlined">notifications</div>
                <span>0</span>
              </div>
              <Avatar
                onClick={(e) => handleMenu(e)}
                className="topbarAvatar"
                src={assets.images.logo}
              />
              <Menu
                anchorEl={anchorEl}
                open={openDropdown}
                onClose={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "left", vertical: "top" }}
                anchorOrigin={{ horizontal: "left", vertical: "top" }}
              >
                <MenuItem onClick={onProfilePage}>
                  <Avatar /> Profile
                </MenuItem>

                <MenuItem onClick={onPressLogout}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      {isOpen && (
        <AlertConfirmation
          onCancel={() => setIsOpen(false)}
          isOpen={isOpen}
          onAccept={onAcceptForLogout}
        />
      )}
    </>
  );
};

export default Topbar;

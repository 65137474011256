import { get } from "lodash";
import BaseService from "./Base";
import ApiUrls from "./ApiUrl";
import { LeadDataBodyParam } from "types/Inbox";

export default class InboxService extends BaseService {

  async getLeadStatus(param: LeadDataBodyParam = {}) {
    try {
      const response = await this.post(ApiUrls.leadStatus + "/" + "list", param);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  async leadList(params = {}) {
    try {
      const response = await this.post(
        ApiUrls.lead + "/" + "list",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getSecretKey(params = {}) {
    try {
      const response = await this.post(
        ApiUrls.lead + "/" + "get/lead-data/parameters",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async updateLead(params = {}) {
    try {
      const response = await this.post(
        ApiUrls.lead + "/" + "update/status",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async deleteLead(params = {}) {
    try {
      const response = await this.post(
        ApiUrls.lead + "/delete",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  //   async deleteClient(params = {}) {
  //     const response = await this.post(
  //       ApiUrls.clientDelete,
  //       params
  //     );
  //     return response;
  //   }

  //   async createClient(params = {}) {
  //     const response = await this.post(
  //       ApiUrls.clientCreate,
  //       params
  //     );
  //     return response;
  //   }

  //   async updateClient(params = {}) {
  //     const response = await this.post(
  //       ApiUrls.clientUpdate,
  //       params
  //     );
  //     return response;
  //   }
}

import Routes from "./routes";
import { ScrollTop } from "./components";
import { ToastContainer } from "react-toastify";
import * as Sentry from "@sentry/react";
import { GlobalErrorBoundary } from "components/GlobalErrorBoundry";
import { useAuth, useIsStaff } from "hooks/useAuth";
import { useEffect } from "react";
import { useAppDispatch } from "redux/store";
import { getUserFromStore } from "redux/features/appStateSlice";

function App() {
  const auth = useAuth();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!auth.user) {
      dispatch(getUserFromStore());
    }
  }, []);

  return (
    <Sentry.ErrorBoundary
      fallback={(errorData) => <GlobalErrorBoundary {...errorData} />}
    >
      <ScrollTop>
        <Routes />
        <ToastContainer />
      </ScrollTop>
    </Sentry.ErrorBoundary>
  );
}

export default App;

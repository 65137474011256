const assets = {
  images: {
    logo: require("./images/userChatHistory.png"),
    mainLogo: require("./images/LOGO1.png"),
    defaultLogo: require("./images/logoo.png"),
    admin: require("./images/Orden-logo-white.png"),
    menuIcon: require("./images/option.png"),
    textIcon: require("./images/text.png"),
  },
};

export default assets;

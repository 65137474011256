import { ConfigureStoreOptions, configureStore } from "@reduxjs/toolkit";
import appStateSlice from "./features/appStateSlice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import projectSlice from "./features/projectSlice";
import chatbotSlice from "./features/chatbotSlice";
import clientSlice from "./features/clientSlice";
import promptSlice from "./features/promptSlice";
import pricingFeatureSlice from "./features/pricingFeatureSlice";
import pricingSlice from "./features/pricingSlice";
import inboxSlice from "./features/inboxSlice";
import questionAnswerSlice from "./features/questionAnswerSlice"
import validationTypeSlice  from "./features/dataParameterValidation";
import countTokenSlice  from "./features/countTokenSlice";

export const createStore = (
  options?: ConfigureStoreOptions['preloadedState'] | undefined
) =>
  configureStore({
    reducer: {
      appState: appStateSlice,
      projectState: projectSlice,
      chatbotState: chatbotSlice,
      clientState: clientSlice,
      promptState: promptSlice,
      pricingFeatureState: pricingFeatureSlice,
      pricingState: pricingSlice,
      inboxState: inboxSlice,
      questionAnswerState: questionAnswerSlice,
      validationTypeState: validationTypeSlice,
      customValidationParameterState: validationTypeSlice,
      countTokenState: countTokenSlice
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }),
    ...options,
  })

export const store: any = createStore()

export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
export type RootState = ReturnType<typeof store.getState>
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector
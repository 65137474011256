import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { dataParameterValidationService } from "services";
import { MetaPagination } from "types/Others";
import {
  ValidationTypeParam,
  customValidationParameterParam,
} from "types/dataParameterValidation";

type ValidationState = {
  data: Array<any>;
  meta: MetaPagination;
  total: number;
  isLoading: boolean;
  customParamater: Array<any>;
  customParameterMeta: MetaPagination;
  customParameterTotal: number;
  customParameterIsLoading: boolean;
};

export const getValidationTypeFromStore = createAsyncThunk(
  "auth/getValidationTypeFromStore",
  async (param: ValidationTypeParam = {}) => {
    return await dataParameterValidationService.getValidationType(param);
  }
);

export const getCustomValidationParameterFromStore = createAsyncThunk(
  "auth/getCustomValidationParameterFromStore",
  async (param: customValidationParameterParam = {}) => {
    return await dataParameterValidationService.getCustomValidationParameter(
      param
    );
  }
);

const initialState: ValidationState = {
  data: [],
  meta: {},
  total: 0,
  isLoading: true,
  customParamater: [],
  customParameterMeta: {},
  customParameterTotal: 0,
  customParameterIsLoading: true,
};

export const validationTypeSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    resetValidationType: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getValidationTypeFromStore.fulfilled, (state, action) => {
        const { data, meta, total } = action.payload;
        state.data = data;
        state.meta = meta;
        state.total = total;
        state.isLoading = false;
      })
      .addCase(getValidationTypeFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.total = 0;
      });

    builder
      .addCase(
        getCustomValidationParameterFromStore.fulfilled,
        (state, action) => {
          const { data, meta, total } = action.payload;
          state.customParamater = data;
          state.customParameterMeta = meta;
          state.customParameterTotal = total;
          state.customParameterIsLoading = false;
        }
      )
      .addCase(
        getCustomValidationParameterFromStore.rejected,
        (state, action) => {
          state.customParameterIsLoading = false;
          state.customParameterTotal = 0;
        }
      );
  },
});

export const { resetValidationType } = validationTypeSlice.actions;

export default validationTypeSlice.reducer;
export const selectValidationType = (state: RootState) =>
  state.validationTypeState.data;
export const selectCustomValidationParameter = (state: RootState) =>
  state.customValidationParameterState.customParamater;

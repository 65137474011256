import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import styles from "./Component.module.css";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DeleteConfirmation({
  title = "Confirmation",
  description = "Are you sure?",
  isOpen = true,
  acceptText = "Confirm",
  disagreeText = "Cancel",
  onAccept = () => null,
  onCancel = () => null,
  onLoading = false,
  projectMsg = "",
}) {
  const [open, setOpen] = React.useState(isOpen);

  const handleClose = (event) => {
    event.stopPropagation();
    setOpen(false);
    onCancel();
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className={styles.dialogDescription}
          >
            {description}
            <br />
            <b>{projectMsg}</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e: any) => handleClose(e)}>{disagreeText}</Button>
          <Button
            disabled={onLoading}
            onClick={(e: any) => {
              onAccept();
            }}
          >
            {onLoading ? (
              <Box sx={{ display: "flex" }}>
                <CircularProgress size={"1.5rem"} />
              </Box>
            ) : (
              acceptText
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default React.memo(DeleteConfirmation);

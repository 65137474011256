import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { clientService, projectService } from "services";
import { ClientsResponse, ClientsBodyParam } from "types/Clients";
import { MetaPagination } from "types/Others";

type ClientsState = {
  clients: Array<ClientsResponse>
  meta: MetaPagination
  total: number
  isLoading: boolean
}

export const getClientFromStore = createAsyncThunk(
  'auth/getClientsFromStore',
  async (param: ClientsBodyParam = {}) => {
    return await clientService.getClients(param);
  }
)

const initialState: ClientsState = {
  clients: [],
  meta: {},
  total: 0,
  isLoading:true
}

export const clientSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
 
  },
  extraReducers: (builder) => {
    builder.addCase(getClientFromStore.fulfilled, (state, action) => {
      if(action?.payload){
        const {data, meta, total} = action?.payload;
        state.clients = data;
        state.meta = meta;
        state.total = total;
        state.isLoading = false;
      }
    }).addCase(getClientFromStore.rejected, (state, action) => {
      state.isLoading = false;
      state.total = 0;
    });
  }
});

export const {
} = clientSlice.actions;

export default clientSlice.reducer;
export const selectClients = (state: RootState) => state.clientState;

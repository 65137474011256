import { useRoutes } from 'react-router-dom';

// project import
import LoginRoutes from './AuthRoutes';
import MainRoutes from './MainRoutes';
import { getToken } from 'helpers/storage';
import { useEffect, useState } from 'react';
import { useAuth } from 'hooks/useAuth';
import FullPageRoutes from './fullPageRoutes';

export default function ThemeRoutes() {
  const [routes, setRoutes] = useState<any[]>([LoginRoutes, MainRoutes, FullPageRoutes]);

  useEffect(()=> {
    const token = getToken();
    if(!!token){
      setRoutes([MainRoutes, LoginRoutes, FullPageRoutes]);
    }
  }, []);

  return useRoutes(routes);
}

// import { ReactNode } from "react";
// import { Route } from "react-router-dom";
// import PageWrapper from "../components/layout/PageWrapper";
// import appRoutes from "./appRoutes";
// import { RouteType } from "./config";

// const generateRoute = (routes: RouteType[]): ReactNode => {
//   return routes.map((route, index) => (
//     route.index ? (
//       <Route
//         index
//         path={route.path}
//         element={<PageWrapper state={route.state}>
//           {route.element}
//         </PageWrapper>}
//         key={index}
//       />
//     ) : (
//       <Route
//         path={route.path}
//         element={
//           <PageWrapper state={route.child ? undefined : route.state}>
//             {route.element}
//           </PageWrapper>
//         }
//         key={index}
//       >
//         {route.child && (
//           generateRoute(route.child)
//         )}
//       </Route>
//     )
//   ));
// };

// export const routes: ReactNode = generateRoute(appRoutes);
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import styles from "./Component.module.css";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type AlertProps = {
  title?: string;
  description?: string;
  isOpen?: boolean;
  acceptText?: string;
  disagreeText?: string;
  onAccept?: () => void;
  onCancel?: () => void;
};

function AlertConfirmation({
  title = "Confirmation",
  description = "Are you sure you want to log out?",
  isOpen = true,
  acceptText = "Confirm",
  disagreeText = "Cancel",
  onAccept = () => null,
  onCancel = () => null,
}: AlertProps) {
  const [open, setOpen] = React.useState(isOpen);

  const handleClose = (event) => {
    event.stopPropagation();
    setOpen(false);
    onCancel();
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className={styles.dialogDescription}
          >
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e: any) => handleClose(e)}>{disagreeText}</Button>
          <Button
            onClick={(e: any) => {
              handleClose(e);
              onAccept();
            }}
          >
            {acceptText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default React.memo(AlertConfirmation);

import Loadable from "./Loadable";
import Loader from "./Loader";
import ScrollTop from "./ScrollTop";
import MainLayout from "./layout/MainLayout";
import MinimalLayout from "./layout/MinimalLayout";
import PageWrapper from "./layout/PageWrapper";
import AlertConfirmation from "./AlertConfirmation";
import Script from "./generateScript"
import addProject from "./addProject";

export {
  Loadable,
  Loader,
  ScrollTop,
  MainLayout,
  MinimalLayout,
  PageWrapper,
  AlertConfirmation,
  Script,
  addProject,
} 
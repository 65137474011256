import { get } from "lodash";
import BaseService from "./Base";
import ApiUrls from "./ApiUrl";
import { PricingFeatureBodyParam } from "types/pricingFeature";

export default class PricingFeatureService extends BaseService {
  
  async getPricingFeature(param: PricingFeatureBodyParam = {}) {
    try{
      const response = await this.post(ApiUrls.pricingFeature + "/"+ "list", param);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

//   async deleteClient(params = {}) {
//     const response = await this.post(
//       ApiUrls.clientDelete,
//       params
//     );
//     return response;
//   }

  async createClient(params = {}) {
    try{
      const response = await this.post(
        ApiUrls.pricingFeature + "/"+ "create",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async updatePricingFeature(params = {}) {
    try{
      const response = await this.post(
        ApiUrls.pricingFeature + "/"+ "update",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  } 
  async deleteFeature(params = {}) {
    try{
      const response = await this.post(
        ApiUrls.pricingFeature + "/"+ "delete",
        params
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
}

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton, Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import DialogContent from "@mui/material/DialogContent";
import Card from "@mui/material/Card";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    // padding: theme.spacing(2),
    height: theme.spacing(30),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props: any) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

type scriptProps = {
  value?: any;
  open?: boolean;
  handleClose?: any;

};

function AddProject({ 
    value,
    open,
    handleClose
}: scriptProps) {
  
  const [isOpen, setIsOpen] = React.useState(open);
  const [isCopy, setIsCopy] = React.useState(false);

  React.useEffect(() => {
    setIsOpen(open);    
  }, [open]);

  const handleClick = () => {
  };


  return (
    <>
      <BootstrapDialog
        // onClose={()=>setIsOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
      >
        <BootstrapDialogTitle onClose={() => {handleClose()}}>
          Script Code
        </BootstrapDialogTitle>

        <DialogContent dividers>
          <Card
            variant="outlined"
            className="mt-2"
            sx={{ padding: 2, backgroundColor: "#f0f5f5" }}
          >
            <code>{value}</code>
          </Card>

          <div className="centerCopyBtn">
            <Button
              autoFocus
              // onClick={handleClick}
              className="copy-btn mt-2"
              type="submit"
            >
              Copy Script
            </Button>
          </div>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
}

export default React.memo(AddProject);

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs-menu button {
    border: 1px solid #f3f3f3;
    border-radius: 50px;
}

.tabs-menu button.Mui-selected {
    background-color: var(--color-icon-orange);
    color: var(--color-white);
    border: 0;
}

.tabs-menu .MuiTabs-flexContainer{
    gap: 10px;
}

.tabs-menu .MuiTabs-indicator {
    height: 0;
}

.bg-class {
    background: var(--color-white);
    padding: 0;
    height: auto;
    border-radius: 20px;
    margin: 25px 0px;
}

.bg-class .MuiBox-root {
    padding: 10px 0;
}

.iconAlign{
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/contentLibrary/styles.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,0CAA0C;IAC1C,yBAAyB;IACzB,SAAS;AACb;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,8BAA8B;IAC9B,UAAU;IACV,YAAY;IACZ,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB","sourcesContent":[".tabs-menu button {\n    border: 1px solid #f3f3f3;\n    border-radius: 50px;\n}\n\n.tabs-menu button.Mui-selected {\n    background-color: var(--color-icon-orange);\n    color: var(--color-white);\n    border: 0;\n}\n\n.tabs-menu .MuiTabs-flexContainer{\n    gap: 10px;\n}\n\n.tabs-menu .MuiTabs-indicator {\n    height: 0;\n}\n\n.bg-class {\n    background: var(--color-white);\n    padding: 0;\n    height: auto;\n    border-radius: 20px;\n    margin: 25px 0px;\n}\n\n.bg-class .MuiBox-root {\n    padding: 10px 0;\n}\n\n.iconAlign{\n    text-align: left;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import {inboxService} from "services";
import { MetaPagination } from "types/Others";
import { LeadDataBodyParam } from "types/Inbox";

type InboxStatusState = {
  leadStatus: Array<any>
  meta: MetaPagination
  total: number
}

export const getLeadStatusFromStore = createAsyncThunk(
  'auth/getLeadStatusFromStore',
  async (param: LeadDataBodyParam = {}) => {
    return await inboxService.getLeadStatus(param);
  }
)

const initialState: InboxStatusState = {
  leadStatus: [],
  meta: {},
  total: 0
}

export const inboxSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    resetInbox: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(getLeadStatusFromStore.fulfilled, (state, action) => {            
      const {data, meta, total} = action.payload;      
      state.leadStatus = data;
      state.meta = meta;
      state.total = total;
    }).addCase(getLeadStatusFromStore.rejected, (state, action) => {
      state.total = 0;
    });
  }
});

export const {resetInbox} = inboxSlice.actions;

export default inboxSlice.reducer;
export const selectLeadStatus = (state: RootState) => state.inboxState?.leadStatus;

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { chatbotService } from "services";

type CountTokenState = {
  data: Array<any>
  isLoading:boolean
}

export const getCountTokenFromStore = createAsyncThunk(
  'auth/getCountTokenFromStore',
  async (param: any = {}) => {
    return await chatbotService.getCountToken(param);
  }
)

const initialState: CountTokenState = {
  data:[],
  isLoading:true
}

export const countTokenSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    resetCountToken: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(getCountTokenFromStore.fulfilled, (state, action) => {
      const {data} = action.payload;
      state.data = data;
      state.isLoading = false
    }).addCase(getCountTokenFromStore.rejected, (state, action) => {
      state.isLoading = false;
    });
  }
});

export const {resetCountToken} = countTokenSlice.actions;

export default countTokenSlice.reducer;
export const selectCountToken = (state: RootState) => state.countTokenState;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error_errorWrapper__8FXiT {
    /* position: absolute; */
    /* display: flex; */
    /* justify-content: center;
    align-items: center; */
    /* left: 10px; */
    /*margin-top: -4px;*/
}

.error_errorWrapper__8FXiT > p {
    font-size: 12px;
    font-weight: 500;
    color: var(--error-400-base);
    margin: 0;
}

.error_warningIcon__FkUmb {
    margin-right: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/auth/Error/error.module.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,mBAAmB;IACnB;0BACsB;IACtB,gBAAgB;IAChB,oBAAoB;AACxB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,4BAA4B;IAC5B,SAAS;AACb;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".errorWrapper {\n    /* position: absolute; */\n    /* display: flex; */\n    /* justify-content: center;\n    align-items: center; */\n    /* left: 10px; */\n    /*margin-top: -4px;*/\n}\n\n.errorWrapper > p {\n    font-size: 12px;\n    font-weight: 500;\n    color: var(--error-400-base);\n    margin: 0;\n}\n\n.warningIcon {\n    margin-right: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorWrapper": `error_errorWrapper__8FXiT`,
	"warningIcon": `error_warningIcon__FkUmb`
};
export default ___CSS_LOADER_EXPORT___;

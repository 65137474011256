import { useMemo } from "react";
import {
  selectCurrentUser,
  selectIsLoaded,
  selectIsLoading,
} from "redux/features/appStateSlice";
import { useTypedSelector } from "redux/store";

export const useAuth = () => {
  const user = useTypedSelector(selectCurrentUser);
  return useMemo(() => ({ user }), [user]);
};

export const useIsStaff = () => {
  const user = useTypedSelector(selectCurrentUser);
  const isStaff = user?.roleId == 4 ? true : false;
  return useMemo(() => isStaff, [isStaff]);
};

export const useIsLoadingAuth = () => {
  const isLoading = useTypedSelector(selectIsLoading);
  return useMemo(() => isLoading, [isLoading]);
};

export const useIsLoadedAuth = () => {
  const isLoaded = useTypedSelector(selectIsLoaded);
  return useMemo(() => isLoaded, [isLoaded]);
};

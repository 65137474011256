import { colors } from "@mui/material";

const colorConfigs = {
  default: {
    sidebar: {
      bg: "#f2f2f2",
      color: "#2a2a2a",
      hoverColor: "#262626",
      hoverBg: "#e6e6e6",
      activeBg: "#f9f9f9",
      activeText: '#2a2a2a',
      iconColor: "#ee783a",
      gradientColorSidebar: "#f2f2f2",
    },
    toolbar: {
      bg: "#f2f2f2",
      color: "#2a2a2a",
      shadow: "0px 0px 2px 0px #838383",
    },
    topbar: {
      bg: "#000",
      color: "#000",
      topbarGradient: "#f2f2f2"
    },
  },
  jaipuria: {
    sidebar: {
      bg: "#0c54a0",
      color: "#ffffff",
      hoverColor: "#ffffff",
      // hoverBg: "#5f85a8",
      hoverBg: "#f28d30",
      activeBg: "#fd8212",
      activeText: '#ffffff',
      iconColor: "#ffffff",
      gradientColor: "radial-gradient(414px at 40.4% 100%, #0A4567 0%, #0A4567E0 100.2%);",
      gradientColorSidebar: "linear-gradient(90deg, #030F59 0%, #030F59 10.2%);",
    },
    toolbar: {
      bg: "#cee9ef",
      color: "#0c54a0",
      shadow: "0px 0px 2px 0px #838383",
    },
    topbar: {
      bg: "#fff",
      color: "#000",
      topbarGradient: "linear-gradient(260deg, #98afcd 50.6%, #030f59 90.2%)",
    },
  },
  mainBg: colors.grey["100"]
};

export default colorConfigs;
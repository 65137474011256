import { ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import colorConfigs from "../../configs/colorConfigs";
import { RootState } from "../../redux/store";
import { RouteType } from "../../routes/config";
import { useEffect, useState } from "react";

type Props = {
  item: RouteType;
  open?: boolean;
  isSub?: boolean;
};

const SidebarItem = ({ item, open = false, isSub = false }: Props) => {
  const { appState } = useSelector((state: RootState) => state.appState);
  const clientType = process.env.REACT_APP_CLIENT_TYPE || "default";
  const [colors, setColors] = useState(colorConfigs.default);
  const location = useLocation();

  useEffect(()=> {
    setColors(clientType == 'jaipuria' ? colorConfigs.jaipuria : colorConfigs.default);
  }, [])
 
  return (
    item.sidebarProps && item.path ? (
      <ListItem key={item.sidebarProps.displayText} disablePadding sx={{ display: 'block', paddingLeft: (isSub && open) ? 2 : 0 }}>
        <ListItemButton
          component={Link}
          to={item.path}
          sx={{
            "&: hover": {
              backgroundColor: colors.sidebar.hoverBg,
              color: colors.sidebar.hoverColor,
            },
            color: location.pathname?.includes(item.path) ? colors.sidebar.activeText : colors.sidebar.color,
            backgroundColor: !!location.pathname?.includes(item.path) ? colors.sidebar.activeBg : "unset",
            paddingY: "12px",
            paddingX: "15px",
            margin: "10px 15px",
            borderRadius: "10px",
            justifyContent: open ? 'initial' : 'center'
          }}
        >
          <ListItemIcon sx={{
            color: location.pathname?.includes(item.path) ? colors.sidebar.iconColor : colors.sidebar.iconColor,
            minWidth: 0,
            mr: open ? 3 : 'auto',
            justifyContent: 'center',
          }}>
            {item.sidebarProps.icon && item.sidebarProps.icon}
          </ListItemIcon>
          <ListItemText primary={item.sidebarProps.displayText} sx={{ opacity: open ? 1 : 0 }} />
          {/* {item.sidebarProps.displayText} */}
        </ListItemButton>
      </ListItem>
    ) : null
  );
};

export default SidebarItem;
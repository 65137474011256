import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Tabs,
  Tab,
  Button,
  Stack,
  Tooltip,
  IconButton,
} from "@mui/material";
import { chatbotService } from "services";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { toastError, toastSuccess } from "helpers/toastHelper";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import "./styles.css";
import { styled } from "@mui/material/styles";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteConfirmation from "components/deleteConfirmation";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const initialState = {
  page: 1,
  pageSize: 10,
  total: 0,
  pdfDocument: null,
  csvDocument: null,
  sourceType: "sitemap",
  webUrlPath: [{ path: "" }],
  siteMapValue: null,
  text: "",
  open: false,
  embeddingId: null,
  textPopupOpen: false,
  textData: "",
  isSubmitLoading: false,
  isListLoading: false,
  isDeleting: false,
};

const Contentlibrary = () => {
  const [currentTab, setCurrentTab] = React.useState(0);
  const [contentEmbeddedList, setContentEmbeddedList] = useState([]);
  const [
    {
      page,
      pageSize,
      total,
      pdfDocument,
      csvDocument,
      sourceType,
      webUrlPath,
      siteMapValue,
      text,
      open,
      embeddingId,
      textPopupOpen,
      textData,
      isSubmitLoading,
      isListLoading,
      isDeleting,
    },
    setState,
  ] = useState(initialState);
  const pdfRef: any = useRef(null);
  const csvRef: any = useRef(null);

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event: any, newValue: any) => {
    setCurrentTab(newValue);
  };

  const handleChangeTab = (event: any, newValue: any) => {
    setState((prevState) => ({
      ...prevState,
      qAndAView: newValue,
    }));
  };

  const getClientPromptEmbeddingList = async (_page = page, _pageSize = pageSize) => {
    try {
      loaderHandler(true);

      let payload = {
        filter: {},
        range: {
          page,
          pageSize,
        },
      };

      payload.range.page = _page || 1;
      payload.range.pageSize = _pageSize || 10;

      if (currentTab == 0) {
        payload.filter["embeddingType"] = ["pdf"];
      }
      //  else if (currentTab == 1) {
      //   payload.filter["embeddingType"] = ["csv"];
      // }
      else if (currentTab == 1) {
        payload.filter["embeddingType"] = ["web-url", "sitemap"];
      } else {
        payload.filter["embeddingType"] = ["text"];
      }

      const response = await chatbotService.clientPromptEmbededList(payload);

      if (response?.data?.success) {
        setState((prevState) => ({
          ...prevState,
          isListLoading: false,
          total: response.data.total,
        }));
        setContentEmbeddedList(response?.data?.data);
      }
    } catch (error) {
      loaderHandler();
    }
  };

  const loaderHandler = (isTrue = false) => {
    setState((prevState) => ({
      ...prevState,
      isListLoading: isTrue,
    }));
  }

  useEffect(() => {
    getClientPromptEmbeddingList();
  }, [currentTab]);

  const columns: GridColDef[] = [
    { field: "fileName", headerName: "Name", cellClassName: "capitalize", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (cellValues) => contentLibAction(cellValues),
    },
  ];

  const WebUrlcolumns: GridColDef[] = [
    { field: "path", headerName: "Name", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (cellValues) => contentLibAction(cellValues),
    },
  ];

  const contentLibAction = (cellValues) => {
    return (
      <Stack direction="row" spacing={1}>
        <Tooltip title="Delete Documents" placement="right">
          <IconButton
            aria-label="Delete"
            color="error"
            size={"large"}
            onClick={(e) => deletePopupOpen(e, cellValues)}
          >
            <DeleteIcon fontSize="inherit"/>
          </IconButton>
        </Tooltip>
        <Tooltip title="View" placement="right">
          <IconButton
            aria-label="view"
            color="primary"
            onClick={(e: any) => view(e, cellValues)}
          >
            <RemoveRedEyeIcon fontSize="inherit"/>
          </IconButton>
        </Tooltip>
      </Stack>
    );
  }

  const view = (e, cellValue) => {
    if (currentTab == 2) {
      setState((prevState) => ({
        ...prevState,
        textPopupOpen: true,
        textData: cellValue["row"].fileName,
      }));
    } else {
      window.open(cellValue?.row?.path, "_blank", "noopener,noreferrer");
    }
  };

  const deletePopupOpen = (e, cellValues) => {
    setState((prevState) => ({
      ...prevState,
      open: true,
      embeddingId: cellValues["row"].clientPromptEmbeddingId,
    }));
  };

  const handleDelete = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isDeleting: true,
      }));
      let payload = {
        clientPromptEmbeddingId: embeddingId,
      };
      const response = await chatbotService.deleteClientPrompt(payload);
      if (response?.data?.success) {
        setState((prevState) => ({
          ...prevState,
          isDeleting: false,
          open: false,
        }));
        toastSuccess(response?.data?.message);
        getClientPromptEmbeddingList();
      }
    } catch (e) {
      setState((prevState) => ({
        ...prevState,
        isDeleting: false,
        open: false,
      }));
      toastError(e["response"]?.data?.message);
    }
  };

  const handlePdfDocument = (event) => {
    const file = event.target.files[0];
    if (!!file) setState((prevState) => ({ ...prevState, pdfDocument: file }));
  };

  const handleCsvDocument = (event) => {
    const file = event.target.files[0];
    if (!!file) setState((prevState) => ({ ...prevState, csvDocument: file }));
  };

  const handleSourceType = (event) => {
    setState((prevState) => ({
      ...prevState,
      sourceType: event.target.value,
      siteMapValue: "",
      webUrlPath: [{ path: "" }],
    }));
  };

  const getPathValue = (event, i) => {
    const { name, value } = event.target;
    const onChangeReqVal = structuredClone(webUrlPath);

    onChangeReqVal[i][name] = value;
    setState((prevState) => ({
      ...prevState,
      webUrlPath: onChangeReqVal,
    }));
  };

  const getSiteMapValue = (event) => {
    const { value } = event.target;
    setState((prevState) => ({
      ...prevState,
      siteMapValue: value,
    }));
  };

  const getTextValue = (event) => {
    setState((prev) => ({
      ...prev,
      text: event.target.value,
    }));
  };

  const submitForm = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isSubmitLoading: true,
      }));
      let formData = new FormData();
      if (currentTab == 0) {
        formData.append("embeddingType", "pdf");
        formData.append("file", pdfDocument);
      }
      // if (currentTab == 1) {
      //   formData.append("embeddingType", "csv");
      //   formData.append("file", csvDocument);
      // }

      if (currentTab == 1) {
        formData.append("embeddingType", sourceType);
        if (sourceType == "sitemap") {
          formData.append("path", siteMapValue);
        } else {
          formData.append("path", JSON.stringify(webUrlPath));
        }
      }

      if (currentTab == 2) {
        formData.append("embeddingType", "text");
        formData.append("path", text);
      }

      const response = await chatbotService.createClientPromp(formData);

      if (response?.status === 200) {
        setState((prevState) => ({
          ...prevState,
          isSubmitLoading: false,
        }));
        getClientPromptEmbeddingList();
        reset();
        resetFileField();
        toastSuccess(
          response?.data?.message ?? "Document uploaded successfully"
        );
      }
    } catch (err) {
      setState((prevState) => ({
        ...prevState,
        isSubmitLoading: false,
      }));
      resetFileField();
    }
  };

  const reset = () => {
    setState((prev) => ({
      ...prev,
      pdfDocument: null,
      csvDocument: null,
      sourceType: "sitemap",
      path: "",
      webUrlPath: [{ path: "" }],
      siteMapValue: "",
      text: "",
    }));
  };

  const resetFileField = () => {
    if (pdfRef?.current?.value) pdfRef.current.value = null;
    if (csvRef?.current?.value) csvRef.current.value = null;
  };

  const addNewRow = (event, index) => {
    event.preventDefault();
    setState((prevState) => ({
      ...prevState,
      webUrlPath: [...webUrlPath, { path: "" }],
    }));
  };

  const deleteWebUrlRow = (event, index) => {
    event.preventDefault();
    const deletedData = [...webUrlPath];
    deletedData.splice(index, 1);
    setState((prevState) => ({
      ...prevState,
      webUrlPath: deletedData,
    }));
  };

  const onCloseTextPopup = () => {
    setState((prevState) => ({
      ...prevState,
      textPopupOpen: false,
    }));
  };

  const onModelChange = (event) => {
    setState((prevState) => ({
      ...prevState,
      page: event.page + 1,
      pageSize: event.pageSize,
    }));

    getClientPromptEmbeddingList(event.page + 1, event.pageSize);
  };

  return (
    <>
      <div className="project_lists">
        <div className="container-fluid">
          <h4 className="my-4 mx-2">Content Library</h4>
          <div className="mt-4 p-3 content-form-card">
            <Box>
              <Box>
                <Tabs
                  className="tabs-menu"
                  value={currentTab}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="PDF" {...a11yProps(0)} />
                  {/* <Tab label="CSV" {...a11yProps(1)} /> */}
                  <Tab label="Web URL" {...a11yProps(1)} />
                  <Tab label="Text" {...a11yProps(2)} />
                </Tabs>
              </Box>
            </Box>
            <div
              style={{
                width: "100%",
                backgroundColor: "white",
                padding: "0px",
                marginBottom: "20px",
              }}
            >
              <Box
                sx={{
                  // boxShadow: 2,
                  width: "100%",
                  height: "auto",
                  bgcolor: (theme) =>
                    theme.palette.mode === "dark" ? "#101010" : "#fff",
                  color: (theme) =>
                    theme.palette.mode === "dark" ? "grey.300" : "grey.800",
                  borderRadius: 2,
                  marginTop: "20px",
                  padding: "10px",
                  textAlign: "center",
                  fontSize: "0.875rem",
                  fontWeight: "700",
                }}
                className="card-box-shadow"
              >
                {currentTab == 0 && (
                  <div className="form-group mb-3 boxPadding">
                    <div className="row">
                      <div className="col-sm-12 col-md-12 contentTitle">
                        <label htmlFor="" className="mt-2">
                          Upload Pdf File
                        </label>
                      </div>
                      <div className="col-sm-12 col-md-12">
                        <input
                          type="file"
                          className="form-control"
                          accept="application/pdf"
                          ref={pdfRef}
                          onChange={(e) => handlePdfDocument(e)}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {/* {currentTab == 1 && (
                    <div className="form-group mb-3 boxPadding">
                      <div className="row">
                        <div className="col-sm-12 col-md-12 contentTitle">
                          <label htmlFor="" className="mt-2">
                            Upload Csv File
                          </label>
                        </div>
                        <div className="col-sm-12 col-md-12">
                          <input
                            type="file"
                            ref={pdfRef}
                            className="form-control"
                            accept="application/csv"
                            onChange={(e) => handleCsvDocument(e)}
                          />
                        </div>
                      </div>
                    </div>
                  )} */}

                {currentTab == 1 && (
                  <>
                    <div className="form-group mb-2 boxPadding">
                      <div className="row">
                        <div className="col-sm-12 col-md-12 contentTitle">
                          <label htmlFor="" className="mt-1">
                            Select Type
                          </label>
                        </div>
                        <div className="col-sm-12 col-md-12">
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                          >
                            <FormControlLabel
                              value="sitemap"
                              control={<Radio />}
                              checked={sourceType == "sitemap"}
                              onChange={(e) => handleSourceType(e)}
                              label="Site Map"
                            />
                            <FormControlLabel
                              value="web-url"
                              control={<Radio />}
                              label="Web Url"
                              checked={sourceType == "web-url"}
                              onChange={(e) => handleSourceType(e)}
                            />
                          </RadioGroup>
                        </div>
                      </div>
                    </div>

                    <div className="form-group mb-3 boxPadding boxMargin">
                      {sourceType == "web-url" ? (
                        webUrlPath?.map((list, i) => (
                          <div key={`url_${i}`} className="row">
                            <div className="col-sm-12 col-md-12 contentTitle">
                              <label htmlFor="" className="mt-2">
                                Enter Web Url
                              </label>
                            </div>
                            <div className="col-sm-12 col-md-11 mt-1">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter web url"
                                name="path"
                                value={list.path || ''}
                                onChange={(e) => getPathValue(e, i)}
                              />
                            </div>
                            <div className="col-sm-1 col-md-1 iconAlign">
                              <>
                                <div
                                  hidden={i == 0}
                                  onClick={(e) => deleteWebUrlRow(e, i)}
                                >
                                  <DeleteOutlineIcon />
                                </div>
                                {i == webUrlPath?.length - 1 && (
                                  <div onClick={(e) => addNewRow(e, i)}>
                                    <AddIcon />
                                  </div>
                                )}
                              </>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="row">
                          <div className="col-sm-12 col-md-12 contentTitle">
                            <label htmlFor="" className="mt-2">
                              Enter Site Map
                            </label>
                          </div>
                          <div className="col-sm-12 col-md-12 mt-1">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter site map"
                              name="path"
                              value={siteMapValue || ''}
                              onChange={(e) => getSiteMapValue(e)}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}

                {currentTab == 2 && (
                  <div className="form-group mb-3 boxPadding">
                    <div className="row">
                      <div className="col-sm-12 col-md-12 contentTitle">
                        <label htmlFor="" className="mt-2">
                          Enter Text
                        </label>
                      </div>
                      <div className="col-sm-12 col-md-12">
                        <textarea
                          name=""
                          className="form-control textAreaHeight"
                          placeholder="Type text here..."
                          value={text}
                          onChange={getTextValue}
                          id=""
                          cols={10}
                          rows={5}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                )}
                <div className="contentBtnDisplay mr-10">
                  <Button
                    autoFocus
                    className="content-save-btn"
                    type="submit"
                    onClick={() => submitForm()}
                  >
                    {isSubmitLoading ? (
                      <Box sx={{ display: "flex" }}>
                        <CircularProgress color="inherit" size={"1.5rem"} />
                      </Box>
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </div>
              </Box>
            </div>

            <div className="contentlistMargin">
              <div
                style={{
                  width: "100%",
                  backgroundColor: "white",
                  padding: "0px",
                }}
              >
                <div
                  style={{
                    height: "50vh",
                    width: "100%",
                    backgroundColor: "white",
                  }}
                >
                  {currentTab == 0 ? (
                    <DataGrid
                      pagination
                      paginationMode="server"
                      rows={contentEmbeddedList}
                      columns={columns}
                      getRowId={(row) => row.clientPromptEmbeddingId}
                      loading={isListLoading}
                      onPaginationModelChange={onModelChange}
                      rowCount={Number(total) || 0}
                      initialState={{
                        pagination: {
                          paginationModel: { page, pageSize },
                        },
                      }}
                      pageSizeOptions={[10, 20, 30, 40, 50]}
                    />
                  ) : (
                    <DataGrid
                      pagination
                      paginationMode="server"
                      rows={contentEmbeddedList}
                      columns={WebUrlcolumns}
                      getRowId={(row) => row.clientPromptEmbeddingId}
                      loading={isListLoading}
                      onPaginationModelChange={onModelChange}
                      rowCount={Number(total) || 0}
                      initialState={{
                        pagination: {
                          paginationModel: { page, pageSize },
                        },
                      }}
                      pageSizeOptions={[10, 20, 30, 40, 50]}
                    />
                  )}
                </div>

                {open && (
                  <DeleteConfirmation
                    onCancel={() =>
                      setState((prev) => ({ ...prev, open: false }))
                    }
                    isOpen={open}
                    onAccept={handleDelete}
                    onLoading={isDeleting}
                  />
                )}

                {textPopupOpen && (
                  <BootstrapDialog
                    aria-labelledby="customized-dialog-title"
                    onClose={() => onCloseTextPopup()}
                    open={textPopupOpen}
                  >
                    <BootstrapDialogTitle onClose={() => onCloseTextPopup()}>
                      View Text
                    </BootstrapDialogTitle>
                    <DialogContent dividers>{textData}</DialogContent>
                  </BootstrapDialog>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contentlibrary;

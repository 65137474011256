// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Component_errorBoundary__erKIt {
  padding: 48px 24px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.Component_errorBoundaryAlert__Eh4EQ {
  width: 100%;
  max-width: 720px;
}

.Component_errorBoundaryRefreshPage__H7ewz {
  text-decoration: underline;
  cursor: pointer;
}

.Component_dialogDescription__-UbVa {
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/Component.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;EAC1B,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".errorBoundary {\n  padding: 48px 24px;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n}\n\n.errorBoundaryAlert {\n  width: 100%;\n  max-width: 720px;\n}\n\n.errorBoundaryRefreshPage {\n  text-decoration: underline;\n  cursor: pointer;\n}\n\n.dialogDescription {\n  margin-top: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorBoundary": `Component_errorBoundary__erKIt`,
	"errorBoundaryAlert": `Component_errorBoundaryAlert__Eh4EQ`,
	"errorBoundaryRefreshPage": `Component_errorBoundaryRefreshPage__H7ewz`,
	"dialogDescription": `Component_dialogDescription__-UbVa`
};
export default ___CSS_LOADER_EXPORT___;

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import {pricingFeatureService} from "services";
import { MetaPagination } from "types/Others";
import { PricingFeatureBodyParam } from "types/pricingFeature";

type PricingFeatureState = {
  features: Array<any>
  meta: MetaPagination
  total: number
}

export const getPricingFeatureFromStore = createAsyncThunk(
  'auth/getPricingFeatureFromStore',
  async (param: PricingFeatureBodyParam = {}) => {
    return await pricingFeatureService.getPricingFeature(param);
  }
)

const initialState: PricingFeatureState = {
  features: [],
  meta: {},
  total: 0
}

export const pricingFeatureSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    resetPF: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(getPricingFeatureFromStore.fulfilled, (state, action) => {
      const {data, meta, total} = action.payload;
      state.features = data;
      state.meta = meta;
      state.total = total;
    }).addCase(getPricingFeatureFromStore.rejected, (state, action) => {
      state.total = 0;
    });
  }
});

export const {resetPF} = pricingFeatureSlice.actions;

export default pricingFeatureSlice.reducer;
export const selectPricingFeature = (state: RootState) => state.pricingFeatureState?.features;

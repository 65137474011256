import BaseService from "./Base";
import ApiUrls from "./ApiUrl";
import { ChatbotBodyParam } from "types/Chatbot";

export default class UserRolesService extends BaseService {
  async getRoleList(param: ChatbotBodyParam = {}) {
    try {
      const response = await this.post(ApiUrls.roleList, param);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
  async getUserList(param = {}) {
    try {
      const response = await this.post(ApiUrls.userList, param);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
  async createUser(param = {}) {
    try {
      const response = await this.post(ApiUrls.createUser, param);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
  async updateUser(param = {}) {
    try {
      const response = await this.post(ApiUrls.updateUser, param);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
  async switchActiveStatus(param = {}) {
    try {
      const response = await this.post(ApiUrls.activeSwitch, param);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
}

import { get } from "lodash";
import BaseService from "./Base";
import ApiUrls from "./ApiUrl";
import { ValidationTypeParam } from "types/dataParameterValidation";

export default class DataParameterValidationService extends BaseService {
  async getValidationType(param: ValidationTypeParam = {}) {
    try {
      const response = await this.post(ApiUrls.validationType, param);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  async getCustomValidationParameter(param: ValidationTypeParam = {}) {
    try {
      const response = await this.post(ApiUrls.customValidationParameter, param);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

}

import { Drawer as MuiDrawer, List } from "@mui/material";
import assets from "../../assets";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import appRoutes, {
  staffRoutes,
  superAdminRoutes,
} from "../../routes/appRoutes";
import SidebarItem from "./SidebarItem";
import SidebarItemCollapse from "./SidebarItemCollapse";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import "./styles.css";
import { useAuth } from "hooks/useAuth";
import { useEffect, useState } from "react";

const openedMixin = (theme: Theme): CSSObject => ({
  width: sizeConfigs.sidebar.width,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 20px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 20px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 0),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const DrawerFooter = styled("div")(() => ({
  display: "flex",
  alignItems: "end",
  paddingBottom: "30px",
  justifyContent: "center",
  height: "100%",
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: sizeConfigs.sidebar.width,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Sidebar = ({ open = false }: any) => {
  const theme = useTheme();
  const auth = useAuth();
  const clientType = process.env.REACT_APP_CLIENT_TYPE || "default";
  const [colors, setColors] = useState(colorConfigs.default);

  useEffect(() => {
    setColors(
      clientType == "jaipuria" ? colorConfigs.jaipuria : colorConfigs.default
    );
  }, []);

  const renderSideRoute = (routes = []) => {
    if (!routes || !routes.length) return "";
    return routes.map(
      (route, index) =>
        (route.sidebarProps ? (
          route.child ? (
            <SidebarItemCollapse item={route} key={index} open={open} />
          ) : (
            <SidebarItem item={route} key={index} open={open} />
          )
        ) : null)
    );
  };

  return (
    <Drawer
      sx={{
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          borderRight: "0px",
          color: colors.sidebar.color,
          background: colors.sidebar.gradientColorSidebar,
        },
      }}
      variant="permanent"
      anchor="left"
      open={open}
    >
      <DrawerHeader className="logo-area mb-0">
        {open ? (
          <>
            {!!auth?.user && (
              <img
                hidden={auth?.user?.roleId != 1}
                src={assets.images.admin}
                alt="admin"
              />
            )}
            {!!auth?.user && (
              <img
                hidden={auth?.user?.roleId == 1}
                src={
                  clientType === "jaipuria"
                    ? assets.images.mainLogo
                    : assets.images.defaultLogo
                }
                alt="client"
              />
            )}
          </>
        ) : (
          <div></div>
        )}
      </DrawerHeader>
      <List disablePadding>
        {/* <Toolbar sx={{ marginBottom: "20px" }}>
          <Stack
            sx={{ width: "100%", textTransform: 'capitalize' }}
            direction="column"
            justifyContent="left"
            alignItems="left"
          >
            <Avatar className="mb-3" src={assets.images.logo} />
            <p className="mb-1">{auth?.user?.firstName || ''} {auth?.user?.lastName || ''}</p>
            <h6>Hello, {auth?.user?.roleId == '1' ? 'Super Admin!' : 'Admin!'}</h6>
          </Stack>
        </Toolbar> */}
        {!!auth?.user && renderSideRoute(auth?.user?.roleId == 1 ? superAdminRoutes : auth?.user?.roleId == 2 ? appRoutes : auth?.user?.roleId == 4 && staffRoutes)}
      </List>
    </Drawer>
  );
};

export default Sidebar;

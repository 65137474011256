import { RouteType } from "./config";
import Dashboard from "../pages/dashboard";
import Project from "../pages/project";
import Chatbots from "../pages/chatbots";
import Membership from "../pages/membership";
import Clients from "pages/superAdmin/clients";
import Prompts from "pages/superAdmin/prompts";
import Contentlibrary from "pages/contentLibrary/contentLibrary";
import Inbox from "pages/inbox/inbox";
// import Roles from "pages/roles/roles";
import Setting from "pages/superAdmin/setting";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import GenericPrompts from "pages/superAdmin/genericPrompts";
import Pricing from "pages/superAdmin/setting/pricing";
import Feature from "pages/superAdmin/setting/feature";
import Billings from "pages/billings";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import Staff from "pages/staff/staff";

export const staffRoutes: RouteType[] = [
  {
    path: "/projects",
    element: <Project />,
    state: "projects",
    sidebarProps: {
      displayText: "Projects",
      icon: <span className="material-symbols-outlined">app_registration</span>,
    },
  },
  {
    path: "/chatbots",
    element: <Chatbots />,
    state: "chatbots",
    sidebarProps: {
      displayText: "Chatbots",
      icon: <span className="material-symbols-outlined">smart_toy</span>,
    },
  },

  {
    path: "/inbox",
    element: <Inbox />,
    state: "inbox",
    sidebarProps: {
      displayText: "Inbox",
      icon: <span className="material-symbols-outlined">archive</span>,
    },
  },
];

const appRoutes: RouteType[] = [
  // {
  //   index: true,
  //   element: <Login />,
  //   state: "login"
  // },
  {
    index: true,
    path: "/dashboard",
    element: <Dashboard />,
    state: "dashboard",
    sidebarProps: {
      displayText: "Dashboard",
      icon: <span className="material-symbols-outlined">dashboard</span>,
    },
  },
  {
    path: "/projects",
    element: <Project />,
    state: "projects",
    sidebarProps: {
      displayText: "Projects",
      icon: <span className="material-symbols-outlined">app_registration</span>,
    },
  },
  {
    path: "/chatbots",
    element: <Chatbots />,
    state: "chatbots",
    sidebarProps: {
      displayText: "Chatbots",
      icon: <span className="material-symbols-outlined">smart_toy</span>,
    },
  },
  {
    path: "/content-library",
    element: <Contentlibrary />,
    state: "content library",
    sidebarProps: {
      displayText: "Content Library",
      icon: <span className="material-symbols-outlined">content_copy</span>,
    },
  },
  {
    path: "/inbox",
    element: <Inbox />,
    state: "inbox",
    sidebarProps: {
      displayText: "Inbox",
      icon: <span className="material-symbols-outlined">archive</span>,
    },
  },
  {
    path: "/staff",
    element: <Staff />,
    state: "staff",
    sidebarProps: {
      displayText: "Staff",
      icon: <span className="material-symbols-outlined">group</span>,
    },
  },
  // {
  //   path: "/billing",
  //   element: <Billings />,
  //   state: "billing",
  //   sidebarProps: {
  //     displayText: "Billings",
  //     icon: <span className="material-symbols-outlined"><AttachMoneyIcon  fontSize="large" style={{fontSize:25}}/></span>,
  //   }
  // },
  // {
  //   path: "/membership",
  //   element: <Membership />,
  //   state: "membership",
  //   sidebarProps: {
  //     displayText: "Membership",
  //     icon: <span className="material-symbols-outlined">heart_check</span>
  //   }
  // }
];
export const superAdminRoutes: RouteType[] = [
  {
    index: true,
    path: "/dashboard",
    element: <Dashboard />,
    state: "dashboard",
    sidebarProps: {
      displayText: "Dashboard",
      icon: <span className="material-symbols-outlined">dashboard</span>,
    },
  },
  {
    path: "/clients",
    element: <Clients />,
    state: "clients",
    sidebarProps: {
      displayText: "Clients",
      icon: <span className="material-symbols-outlined">manage_accounts</span>,
    },
  },
  // {
  //   path: "/prompts",
  //   element: <Prompts />,
  //   state: "prompts",
  //   sidebarProps: {
  //     displayText: "Prompts",
  //     icon: <span className="material-symbols-outlined">terminal</span>,
  //   },
  // },
  // {
  //   path: "/generic-prompts",
  //   element: <GenericPrompts />,
  //   state: "genericprompts",
  //   sidebarProps: {
  //     displayText: "Generic Prompts",
  //     icon: <span className="material-symbols-outlined">terminal</span>,
  //   },
  // },
  // {
  //   path: "/membership",
  //   element: <Membership />,
  //   state: "membership",
  //   sidebarProps: {
  //     displayText: "Membership",
  //     icon: <span className="material-symbols-outlined">heart_check</span>
  //   }
  // },
  // {
  //   path: "/settings",
  //   element: <Setting />,
  //   state: "setting",
  //   sidebarProps: {
  //     displayText: "Settings",
  //     icon: <span className="material-symbols-outlined">settings</span>
  //   },
  //   child: [
  //     {
  //       path: "/settings/feature",
  //       element: <Feature />,
  //       state: "feature",
  //       sidebarProps: {
  //         displayText: "Feature",
  //         icon: <span className="material-symbols-outlined">list_alt</span>
  //       }
  //     },
  //     {
  //       path: "/settings/pricing",
  //       element: <Pricing />,
  //       state: "pricing",
  //       sidebarProps: {
  //         displayText: "Pricing",
  //         icon: <span className="material-symbols-outlined">price_check</span>
  //       }
  //     },
  //   ]
  // }
];
export default appRoutes;

import { useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Box, Toolbar } from "@mui/material";
import colorConfigs from "../../../configs/colorConfigs";
import sizeConfigs from "../../../configs/sizeConfigs";
import Sidebar from "../../common/Sidebar";
import Topbar from "../../common/Topbar";
import { getToken } from "helpers/storage";
import {useTheme } from "@mui/material/styles";

const MainLayout = () => {
  const [open, setOpen] = useState(true);
  let location = useLocation();
  const token = getToken();
  const theme = useTheme();

  if(!token){
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Topbar 
        open={open} 
        handleDrawerOpen={handleDrawerOpen} 
        handleDrawerClose={handleDrawerClose}
      />
      <Box
        component="nav"
        sx={{
          width: !!open ? sizeConfigs.sidebar.width : `calc(${theme.spacing(7)} + 20px)`,
          flexShrink: 0,
          backgroundColor: colorConfigs.mainBg
        }}
      >
        <Sidebar open={open} />
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: open ? `calc(100% - ${sizeConfigs.sidebar.width})` : `calc(100% - ${theme.spacing(7)} + 20px)`,
          minHeight: "100vh",
          backgroundColor: colorConfigs.mainBg
        }}
      >
        {/* <Toolbar /> */}
        <Outlet />
      </Box>
    </Box>
  );
};

export default MainLayout;
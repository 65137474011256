import BaseService from "./Base";
import ApiUrls from "./ApiUrl";
import { Report, filterReport } from "types/clientDashboad";

export default class ClientDashboardService extends BaseService {
  async getReportList(param: Report = {}) {
    try {
      const response = await this.post(`${ApiUrls.report}/list`, param);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  async getLeadGraphListByFilter(param: filterReport = {}) {
    try {
      const response = await this.post(
        `${ApiUrls.report}/list-by-filter`,
        param
      );
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  async getQuestionAndToken(param: filterReport = {}) {
    try {
      const response = await this.post(`${ApiUrls.report}/pie-chart`, param);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
}

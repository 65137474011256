import React, {useEffect, useState} from "react"
import Loader from "./Loader"
import {Alert, AlertTitle, Button} from "@mui/material"
import styles from "./Component.module.css"

type GlobalErrorBoundaryProps = {
    error: Error
    eventId?: string
}

export function GlobalErrorBoundary({error, eventId}: GlobalErrorBoundaryProps) {
    const [isDetailOpen, setIsDetailOpen] = useState(false)

    const isChunkLoadingFailedError = error?.message && /Loading chunk [\d]+ failed/.test(error.message)

    useEffect(() => {
        if (isChunkLoadingFailedError) {
            window.location.reload()
        }
    }, [isChunkLoadingFailedError])

    if (isChunkLoadingFailedError) {
        return <Loader />
    }

    const hasErrorMessage = !!error?.message

    return (
        <div className={styles.errorBoundary}>
            <Alert
                className={styles.errorBoundaryAlert}
                severity="error"
                action={
                    hasErrorMessage ? (
                        <Button size="small" color="error" onClick={() => setIsDetailOpen((it) => !it)}>
                            {isDetailOpen ? "Hide detail" : "Show detail"}
                        </Button>
                    ) : undefined
                }
            >
              <AlertTitle>Oops! Something is not right.</AlertTitle>
              <div>
                <p>
                    If this page does not automatically refresh within 30 seconds, please manually reload it in
                    your browser.
                    <br />
                    If the problem continues to happen please email:{" "}
                    <a
                        href={`mailto:support@essencesoftwares.com?subject=Error%20Message&body=Hello%20klassapp,%20I%20got%20this%20error.%20${error?.message}`}>
                        support@essencesoftwares.com
                    </a>
                </p>
                {hasErrorMessage && isDetailOpen && (
                    <p>
                        {error.message} {eventId ? `(Event ID: ${eventId})` : ""}
                    </p>
                )}
            </div>
          </Alert>
        </div>
    )
}

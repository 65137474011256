import { lazy } from 'react';
import {Loadable, MinimalLayout} from '../components';

const AuthLogin = Loadable(lazy(() => import('../pages/auth/login')));
const AuthRegister = Loadable(lazy(() => import('../pages/auth/register')));
const Error404 = Loadable(lazy(() => import('../pages/auth/404')));
const Verify = Loadable(lazy(() => import('../pages/auth/verify')));
// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/',
      element: <AuthLogin />
    },
    {
      path: 'login',
      element: <AuthLogin />
    },
    {
      path: 'register',
      element: <AuthRegister />
    },
    {
      path: 'verify',
      element: <Verify />
    },
    {
      path: 'error',
      element: <Error404 />
    },
    {
      path: '*',
      element: <Error404 />
    }
  ]
};

export default LoginRoutes;

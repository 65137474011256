// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo-area {
  display: flex !important;
  align-content: center;
  justify-content: center !important;
  /* background: #fff; */
}

.logo-area img {
  width: 60%;
  aspect-ratio: 4/3;
  object-fit: contain;
  padding: 10px;
}

.topbarAvatar {
  position: relative !important;
}

.css-1eaqvnp-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  position: absolute !important;
  top: 50px !important;
  /* left: 1375px !important; */
}
`, "",{"version":3,"sources":["webpack://./src/components/common/styles.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,qBAAqB;EACrB,kCAAkC;EAClC,sBAAsB;AACxB;;AAEA;EACE,UAAU;EACV,iBAAiB;EACjB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,6BAA6B;EAC7B,oBAAoB;EACpB,6BAA6B;AAC/B","sourcesContent":[".logo-area {\n  display: flex !important;\n  align-content: center;\n  justify-content: center !important;\n  /* background: #fff; */\n}\n\n.logo-area img {\n  width: 60%;\n  aspect-ratio: 4/3;\n  object-fit: contain;\n  padding: 10px;\n}\n\n.topbarAvatar {\n  position: relative !important;\n}\n\n.css-1eaqvnp-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {\n  position: absolute !important;\n  top: 50px !important;\n  /* left: 1375px !important; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
